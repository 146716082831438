export const laserTexts = {
  "nav-0": {
    ru: "Главная",
    en: "Main page",
    de: "Hauptseite",
    ua: "Головна",
  },
  "nav-1": {
    ru: "Bestsellers",
    en: "Bestsellers",
    de: "Bestsellers",
    ua: "Bestsellers",
  },
  "nav-2": {
    ru: "О Нас",
    en: "About",
    de: "Über uns",
    ua: "Про нас",
  },
  "nav-3": {
    ru: "Наши Услуги",
    en: "Our Services",
    de: "Dienstleistungen",
    ua: "Наші послуги",
  },
  "nav-4": {
    ru: "Контакты",
    en: "Contacts",
    de: "Kontakte",
    ua: "Контакти",
  },
  "laser_page-title": {
    ru: "Vanille Salon&Spa Александритовый Лазер",
    en: "Vanille Salon&Spa Alexandrite Laser",
    de: "Vanille Salon&Spa Alexandrit-Laser",
    ua: "Vanille Salon&Spa Александритовий Лазер",
  },
  "laser_title-1": {
    ru: "Александритовый Лазер",
    en: "Alexandrite Laser",
    de: "Alexandrit-Laser",
    ua: "Александритовий лазер",
  },
  "laser_title-2": {
    ru: "Откройте для себя преимущества александритового лазера: ваш ключ к идеальной коже",
    en: "DEKA Again: The Alexandrite Laser - The Rolls-Royce Among Laser Systems and a Breakthrough in Modern Cosmetology!",
    de: "Entdecken Sie die Vorteile des Alexandrit-Lasers: Ihr Schlüssel zu perfekter Haut",
    ua: "DEKA Again: Александритовий лазер - Роллс-Ройс серед лазерних систем і прорив сучасної косметології!",
  },
  "laser_title-3": {
    ru: "Александритовый лазер — это высокотехнологичное устройство для лазерной эпиляции, которое заслужило доверие специалистов в области косметологии благодаря своей эффективности и безопасности. Этот лазер подходит для всех, кто стремится к гладкости кожи, и вот почему он должен стать вашим выбором",
    en: "In the world of aesthetic medicine and at the Vanille studio, a true star has emerged - the Alexandrite laser DEKA Again, hailing from Italy, which can be unequivocally called the Rolls-Royce of laser systems. Our clients and Vanille studio specialists eagerly awaited its arrival, and now we proudly introduce this innovation.",
    de: "Der Alexandrit-Laser ist ein hochtechnologisches Gerät für die Laser-Haarentfernung, das das Vertrauen von Fachleuten in der Kosmetologie dank seiner Wirksamkeit und Sicherheit gewonnen hat. Dieser Laser ist für alle geeignet, die glatte Haut anstreben, und deshalb sollte er Ihre Wahl sein",
    ua: "У світі естетичної медицини та в студії Vanille з'явилася справжня зірка - александритовий лазер DEKA Again, родом із Італії, який можна назвати беззаперечним Роллс-Ройсом серед лазерних систем. Наші клієнти та фахівці студії Vanille з нетерпінням чекали його появи, і тепер ми гордо представляємо цей інноваційний продукт.",
  },
  "laser_page-1": {
    ru: "Быстрые результаты",
    en: "The Art of Beauty and Safety",
    de: "Schnelle Ergebnisse",
    ua: "Мистецтво краси та безпеки",
  },
  "laser_page-2": {
    ru: "Александритовый лазер известен своей способностью быстро и эффективно удалять нежелательные волосы. Благодаря длине волны 755 нм, он идеально подходит для воздействия на меланин в волосах, что обеспечивает высокую эффективность лечения даже на светлой и тонкой коже",
    en: "DEKA Again is a fusion of art and high technology in one package. It is based on the Alexandrite laser, which is recognized as one of the most effective, safe, and painless methods for hair removal and pigment removal. This laser allows us to achieve stunning results while ensuring maximum safety for your skin. Our studio is immensely happy to have this miraculous machine!",
    de: "Der Alexandrit-Laser ist bekannt für seine Fähigkeit, unerwünschte Haare schnell und effektiv zu entfernen. Dank seiner Wellenlänge von 755 nm eignet er sich perfekt für die Beeinflussung des Melanins in den Haaren, was eine hohe Behandlungseffizienz auch bei heller und feiner Haut gewährleistet",
    ua: "DEKA Again - це поєднання мистецтва та високих технологій в одному. Він базується на александритовому лазері, який визнаний одним із найефективніших і безпечних методів для видалення волосся та пігментації. Цей лазер дозволяє нам досягнути дивовижних результатів, дотримуючись максимальної безпеки для вашої шкіри. Наша студія щаслива, що у нас є це чудове обладнання!",
  },
  "laser_page-3": {
    ru: "Применим к различным типам кожи",
    en: "Advantages of DEKA Again:",
    de: "Anwendbar auf verschiedene Hauttypen",
    ua: "Переваги DEKA Again:",
  },
  "laser_page-4": {
    ru: "Одно из ключевых преимуществ александритового лазера заключается в его универсальности. Он подходит для различных типов кожи, включая светлые и смуглые тонов. Это делает его идеальным решением для широкого спектра клиентов, желающих избавиться от нежелательных волос на лице и теле",
    en: "DEKA Again is designed for hair removal and pigment removal on the skin with remarkable effectiveness. You will feel the results after the very first procedure. It is currently the only system that can immediately «detect» fine and light hair!",
    de: "Einer der Hauptvorteile des Alexandrit-Lasers liegt in seiner Vielseitigkeit. Er eignet sich für verschiedene Hauttypen, einschließlich heller und dunkler Töne. Dies macht ihn zur idealen Lösung für eine breite Palette von Kunden, die unerwünschte Haare im Gesicht und am Körper loswerden möchten",
    ua: "DEKA Again розроблений для видалення волосся та пігментації на шкірі з дивовижною ефективністю. Ви відчуєте результат вже після першої процедури. Це єдиний на сьогоднішній день система, яка одразу «бачить» тонке й світле волосся!",
  },
  "laser_page-5": {
    ru: "Минимальный дискомфорт",
    en: "Maximum Safety:",
    de: "Minimale Unannehmlichkeiten",
    ua: "Максимальна безпека:",
  },
  "laser_page-6": {
    ru: "Сеансы лазерной эпиляции с использованием александритового лазера характеризуются низким уровнем дискомфорта. Благодаря встроенным системам охлаждения, процедуры становятся почти безболезненными, что делает процесс удобным и терпимым даже для людей с высокой чувствительностью кожи",
    en: "The system ensures maximum safety for your skin. DEKA's developed technology guarantees minimal risks and discomfort during the procedure. You will only feel a pleasant coolness from the handpiece!",
    de: "Die Laser-Haarentfernungssitzungen mit dem Alexandrit-Laser zeichnen sich durch ein geringes Maß an Unannehmlichkeiten aus. Dank eingebauter Kühlsysteme werden die Behandlungen nahezu schmerzfrei, was den Prozess selbst für Menschen mit empfindlicher Haut angenehm und erträglich macht",
    ua: "Система забезпечує максимальну безпеку для вашої шкіри. Розроблена технологія DEKA гарантує мінімальні ризики та дискомфорт під час процедури. Ви відчуєте лише приємний холод від маніпули!",
  },
  "laser_page-7": {
    ru: "Безопасность и эффективность",
    en: "Multitasking",
    de: "Sicherheit und Effektivität",
    ua: "Багатофункціональність",
  },
  "laser_page-8": {
    ru: "Лазеры на александрите считаются одними из самых безопасных для использования в косметологии. Они обеспечивают высокую точность воздействия, что минимизирует риски побочных эффектов, таких как покраснения или ожоги, а также ускоряет процесс восстановления кожи после процедуры",
    en: "DEKA Again is capable of addressing a wide range of tasks, including hair removal, pigment removal, as well as the removal of blood vessels and scars. The laser also works on all 6 skin phototypes.",
    de: "Alexandrit-Laser gelten als eine der sichersten Optionen in der Kosmetik. Sie bieten eine hohe Präzision, was das Risiko von Nebenwirkungen wie Rötungen oder Verbrennungen minimiert, und beschleunigen zudem den Regenerationsprozess der Haut nach der Behandlung",
    ua: "DEKA Again здатний вирішувати різноманітні завдання, включаючи видалення волосся, пігментації, а також судин і шрамів. Лазер також працює на всіх 6 фототипах шкіри",
  },
  "laser_page-9": {
    ru: "Долгосрочные результаты",
    en: "Short Procedure Time",
    de: "Langfristige Ergebnisse",
    ua: "Короткий час процедури",
  },
  "laser_page-10": {
    ru: "После курса лазерной эпиляции с использованием александритового лазера можно добиться долгосрочного, а иногда и постоянного уменьшения волос. Это не только улучшает внешний вид кожи, но и сокращает необходимость в регулярном удалении волос, экономя ваше время и деньги.Александритовый лазер — это современное решение для тех, кто стремится к безупречной коже без нежелательных волос. Запишитесь на консультацию с нашим специалистом, чтобы узнать, подходит ли вам этот метод, и начните свой путь к гладкой, красивой коже уже сегодня",
    en: "DEKA Again is designed for maximum performance, which means a shorter procedure time. You can enjoy the results without spending too much time. In comparison to a diode laser, our Italian system immediately recognizes light hair, which ultimately reduces the number of sessions significantly!",
    de: "Nach einem Kurs der Laser-Haarentfernung mit dem Alexandrit-Laser können langfristige, und manchmal sogar dauerhafte, Ergebnisse erzielt werden. Dies verbessert nicht nur das Aussehen der Haut, sondern reduziert auch die Notwendigkeit regelmäßiger Haarentfernungsbehandlungen, was Zeit und Geld spart. Der Alexandrit-Laser ist eine moderne Lösung für diejenigen, die sich nach makelloser Haut ohne unerwünschte Haare sehnen. Vereinbaren Sie einen Beratungstermin mit unserem Spezialisten, um herauszufinden, ob diese Methode für Sie geeignet ist, und beginnen Sie noch heute Ihren Weg zu glatter, schöner Haut.",
    ua: "DEKA Again розроблений для досягнення максимальної продуктивності, що означає скорочений час проведення процедури. Ви зможете насолоджуватися результатами, не витрачаючи надто багато часу. Порівняно з діодним лазером, наш італієць негайно розпізнає світле волосся, що в кінцевому підсумку значно зменшує кількість сеансів!",
  },
  "laser_page-11": {
    ru: "Долгосрочные Результаты",
    en: "Long-Term Results",
    de: "Langfristige Ergebnisse",
    ua: "Довгострокові результати",
  },
  "laser_page-12": {
    ru: "DEKA Again обеспечивает долгосрочные результаты. После завершения курса процедур, вы сможете наслаждаться гладкой кожей и отсутствием нежелательных волос в течение длительного времени. Достаточно одного курса! Результаты остануться с Вами от 1,5 лет. Кроме этого это самый быстрый способ эпиляциию. Индивидуальный Подход: Новости профессиональных специалистов всегда готова разработать индивидуальный план, который соответствует вашим уникальным потребностям и целям.Лазерную  процедуру можно проводить даже летом и вы не ограничены временем года!",
    en: "DEKA Again provides long-term results. After completing the course of procedures, you can enjoy smooth skin and the absence of unwanted hair for an extended period. One course is sufficient! The results will last for up to 1.5 years. Additionally, this is the fastest method of hair removal. Individual Approach: News of professional specialists is always ready to develop an individual plan that aligns with your unique needs and goals. Laser procedures can be performed even in the summer, and you are not limited by the season!",
    de: "DEKA Again bietet langfristige Ergebnisse. Nach Abschluss des Behandlungszyklus werden Sie lange Zeit glatte Haut und das Fehlen unerwünschter Haare genießen können. Ein Kurs reicht aus! Die Ergebnisse halten bis zu 1,5 Jahre. Außerdem ist dies die schnellste Methode zur Haarentfernung. Individueller Ansatz: Nachricht von Fachleuten ist immer bereit, einen individuellen Plan zu entwickeln, der Ihren einzigartigen Bedürfnissen und Zielen entspricht. Die Laserbehandlung kann sogar im Sommer durchgeführt werden, und Sie sind nicht an eine bestimmte Jahreszeit gebunden!",
    ua: "DEKA Again гарантує довгострокові результати. Після завершення курсу процедур ви зможете насолоджуватися гладкою шкірою і відсутністю небажаного волосся на довгий час. Достатньо одного курсу! Результати залишаться з вами протягом 1,5 року. Крім того, це найшвидший спосіб видалення волосся. Індивідуальний підхід: Новости професіоналів завжди готова розробити індивідуальний план, який відповідає вашим унікальним потребам та цілям. Лазерну процедуру можна проводити навіть влітку, і ви не обмежені сезоном!",
  },
  "laser_page-13": {
    ru: "Не упустите шанс на безупречную кожу!",
    en: "Don't miss the chance for flawless skin!",
    de: "Verpassen Sie nicht die Gelegenheit für makellose Haut!",
    ua: "Не пропустіть можливість мати бездоганну шкіру!",
  },
  "laser_page-14": {
    ru: "",
    en: "",
    de: "",
    ua: "",
  },
  "content-24": {
    ru: "Задать вопрос",
    en: "Ask a question",
    de: "Eine Frage stellen",
    ua: "Поставити питання",
  },
  "content-25": {
    ru: "Имя",
    en: "Name",
    de: "Name",
    ua: "Ім'я",
  },
  "content-26": {
    ru: "Телефон",
    en: "Phone",
    de: "Telefon",
    ua: "Телефон",
  },
  "content-27": {
    ru: "Ваше сообщение",
    en: "Your message",
    de: "Ihre Nachricht",
    ua: "Ваше повідомлення",
  },

  "content-28": {
    ru: "я согласен с обработкой моих данных",
    en: "I consent to the processing of my data",
    de: "Ich stimme der Verarbeitung meiner Daten zu",
    ua: "Я згоден з обробкою моїх даних",
  },
  "content-33": {
    ru: "Наши Услуги",
    en: "Our Services",
    de: "Dienstleistungen",
    ua: "Наші послуги",
  },
  "content-34": {
    ru: "О Нас",
    en: "About",
    de: "Über uns",
    ua: "Про нас",
  },
  "content-35": {
    ru: "Новинки",
    en: "New",
    de: "Neue Dinge",
    ua: "Новінки",
  },
  "content-36": {
    ru: "Контакты",
    en: "Contacts",
    de: "Kontakte",
    ua: "Контакти",
  },
  "content-37": {
    ru: "",
    en: "",
    de: "",
    ua: "",
  },
  "content-38": {
    ru: "",
    en: "",
    de: "",
    ua: "",
  },


  "button": {
    ru: "Онлайн запись",
    en: "Online reservation",
    de: "Online-Reservierung",
    ua: "Запис онлайн",
  },
  "button-10": {
    ru: "Онлайн запись",
    en: "Online reservation",
    de: "Online-Reservierung",
    ua: "Запис онлайн",
  },
  "button-11": {
    ru: "Онлайн запись",
    en: "Online reservation",
    de: "Online-Reservierung",
    ua: "Запис онлайн",
  },
  "button-12": {
    ru: "Онлайн запись",
    en: "Online reservation",
    de: "Online-Reservierung",
    ua: "Запис онлайн",
  },
  "button-13": {
    ru: "Онлайн запись",
    en: "Online reservation",
    de: "Online-Reservierung",
    ua: "Запис онлайн",
  },
};
