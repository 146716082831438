document.querySelectorAll(".select").forEach((el)=> {
  el.addEventListener("click", function (e){
    if (e.target && e.target.matches(".option")) {
      this.querySelector(".shown").innerHTML = e.target.innerHTML;
    }
    this.classList.toggle("collapsed");
  });
}); 


const onLoad = (e) => {
  e.preventDefault();

  setTimeout(() => window.location.replace(e.target.href), 2000)
}



const dropdowns = document.querySelectorAll('.dropdown');

dropdowns.forEach(dropdown => {
    const select = dropdown.querySelector('.select');
    const caret = dropdown.querySelector('.caret');
    const menu = dropdown.querySelector('.menu');
    const options = dropdown.querySelectorAll('.menu li');
    const selected = dropdown.querySelector('.selected');

  
select.addEventListener('click', () => {
  select.classList.toggle('select-clicked');
  caret.classList.toggle('caret-rotate');
  menu.classList.toggle('menu-open');
});

options.forEach(option => {
  option.addEventListener('click', () => {
      selected.innerText = option.innerText;
      select.classList.remove('select-clicked');
      caret.classList.remove('caret-rotate');
      menu.classList.remove('menu-open');
    
      options.forEach(option => {
        option.classList.remove('active');
        });
          option.classList.add('active');
  });
});
});





const mainMenu = document.getElementById("main-menu"),
  chevron = document.getElementById("chevron");


const toggleDropdownButton = () => {
  mainMenu.classList.toggle("open");
  chevron.innerHTML = !mainMenu.classList.contains("open")
    ? "expand_more"
    : "close";
};

const drop = document.getElementById('drop');

drop.addEventListener('click', () => {
  toggleDropdownButton()
});

