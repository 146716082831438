export const homeTexts = {
  "home_page-title": {
    ru: "Vanille Salon Главная",
    en: "Vanille Salon Homepage",
    de: "Vanille Salon Startseite",
    ua: "Vanille Salon Головна",
  },
  "about_page-title": {
    ru: "Vanille Salon&Spa О Нас",
    en: "Vanille Salon&Spa About",
    de: "Vanille Salon&Spa Über uns",
    ua: "Vanille Salon&Spa Про нас",
  },
  "endosphere_page-title": {
    ru: "Vanille Salon&Spa Эндосфера",
    en: "Vanille Salon&Spa Endosphere Therapy",
    de: "Vanille Salon&Spa Endosphères Therapy",
    ua: "Vanille Salon&Spa Ендосфера",
  },
  "services_page-title": {
    ru: "Vanille Salon&Spa Наши Услуги",
    en: "Vanille Salon&Spa Our Services",
    de: "Vanille Salon&Spa Dienstleistungen",
    ua: "Vanille Salon&Spa Наші послуги",
  },
  "spa_page-title": {
    ru: "Vanille Salon&Spa Спа процедуры",
    en: "Vanille Salon&Spa Spa procedures",
    de: "Vanille Salon&Spa Wellnessanwendungen",
    ua: "Vanille Salon&Spa Спа-терапія",
  },
  "team_page-title": {
    ru: "Vanille Salon&Spa Спа процедуры",
    en: "Vanille Salon&Spa News",
    de: "Vanille Salon&Spa Nachricht",
    ua: "Vanille Salon&Spa Новини",
  },
  "nav-0": {
    ru: "Главная",
    en: "Main page",
    de: "Hauptseite",
    ua: "Головна",
  },
  "nav-1": {
    ru: "Bestsellers",
    en: "Bestsellers",
    de: "Bestsellers",
    ua: "Bestsellers",
  },
  "nav-2": {
    ru: "О Нас",
    en: "About",
    de: "Über uns",
    ua: "Про нас",
  },
  "nav-3": {
    ru: "Наши Услуги",
    en: "Our Services",
    de: "Dienstleistungen",
    ua: "Наші послуги",
  },
  "nav-4": {
    ru: "Контакты",
    en: "Contacts",
    de: "Kontakte",
    ua: "Контакти",
  },
  "title-1": {
    ru: "Наша Философия",
    en: "Our Philosophy",
    de: "Unsere Philosophie",
    ua: "Наша філософія",
  },

  "content-1": {
    ru: "VANILLE Salon & SPA Hannover - Ваш путь к лучшей версии себя",
    en: "VANILLE Salon & SPA Hannover - Your Path to the Best Version of Yourself",
    de: "VANILLE Salon & SPA Hannover - Ihr Weg zur besten Version von sich selbst",
    ua: "VANILLE Salon & SPA Hannover - ваш шлях до кращої версії себе",
  },
  "content-2": {
    ru: "Мы видим себя как проводника к гармонии и красоте в мире, где стресс и суета стали повседневными спутниками. Мы верим, что забота о себе и любовь к себе - это необходимые аспекты современной жизни. Наша философия заключается в создании уникального пространства, где вы можете открыть в себе новые грани красоты и спокойствия",
    en: "We see ourselves as guides to harmony and beauty in a world where stress and hustle have become everyday companions. We believe that self-care and self-love are essential aspects of modern life. Our philosophy is centered around creating a unique space where you can discover new facets of beauty and tranquility within yourself",
    de: "Wir sehen uns als Wegweiser zur Harmonie und Schönheit in einer Welt, in der Stress und Hektik alltägliche Begleiter geworden sind. Wir glauben, dass Selbstpflege und Selbstliebe notwendige Aspekte des modernen Lebens sind. Unsere Philosophie besteht darin, einen einzigartigen Raum zu schaffen, in dem Sie neue Facetten Ihrer Schönheit und Gelassenheit entdecken können",
    ua: "Ми бачимо себе як провідників до гармонії та краси в світі, де стрес і поспіх стали повсякденними супутниками. Ми віримо, що догляд за собою та любов до себе - це важливі аспекти сучасного життя. Наша філософія полягає в створенні унікального простору, де ви можете відкрити в собі нові грані краси та спокою.",
  },
  "content-3": {
    ru: "Путешествие к лучшей версии себя В нашей студии каждый гость важен. Мы готовы вас выслушать и предложить персональный путь к вашей лучшей версии. Наши методы и услуги помогут вам расслабиться, восстановить внутренний баланс и почувствовать себя уверенно. Мы страстно работаем над тем, чтобы каждый, кто посещает нашу студию, нашел не только красоту, но и внутренний покой",
    en: "The journey to the best version of yourself is important to every guest at our studio. We are ready to listen to you and offer a personalized path to your best self. Our methods and services will help you relax, restore your inner balance, and feel confident. We passionately work towards ensuring that everyone who visits our studio not only finds outer beauty but also inner peace",
    de: "Die Reise zur besten Version von sich selbst ist in unserem Studio für jeden Gast wichtig. Wir sind bereit, Ihnen zuzuhören und einen persönlichen Weg zu Ihrer besten Version vorzuschlagen. Unsere Methoden und Dienstleistungen helfen Ihnen, sich zu entspannen, Ihr inneres Gleichgewicht wiederherzustellen und sich selbstbewusst zu fühlen. Wir arbeiten leidenschaftlich daran, sicherzustellen, dass jeder, der unser Studio besucht, nicht nur äußere Schönheit findet, sondern auch inneren Frieden",
    ua: "Подорож до кращої версії себе. У нашій студії кожен гість важливий. Ми готові вас вислухати і запропонувати особистий шлях до вашої найкращої версії. Наші методи та послуги допоможуть вам розслабитися, відновити внутрішній баланс і відчути впевненість в собі. Ми пристрасно працюємо, щоб кожен, хто відвідує нашу студію, знайшов не лише красу, а й внутрішній спокій.",
  },
  "content-4": {
    ru: "Качество, забота и уникальность Мы гордимся тем, что предоставляем	высочайшее качество услуг. Специалисты VANILLE Salon & SPA - опытные профессионалы, которые уделяют внимание даже мельчайшим деталям. Наша студия - это не просто место для ухода за собой, это опыт, который мы создали с заботой и любовью к нашим клиентам. Мы хотим помочь вам открыть в себе красоту, которая будет сиять изнутри",
    en: "Quality, care, and uniqueness are of utmost importance to us. The specialists at VANILLE Salon & SPA are experienced professionals who pay attention to even the smallest details. Our studio is not just a place for self-care; it's an experience we've created with care and love for our clients. We want to help you discover the beauty within yourself that will shine from within",
    de: "Qualität, Fürsorge und Einzigartigkeit sind für uns von größter Bedeutung. Die Spezialisten von VANILLE Salon & SPA sind erfahrene Profis, die selbst auf die kleinsten Details achten. Unser Studio ist nicht nur ein Ort der Selbstpflege, sondern eine Erfahrung, die wir mit Sorgfalt und Liebe zu unseren Kunden geschaffen haben. Wir möchten Ihnen helfen, die Schönheit in sich selbst zu entdecken, die von innen strahlen wird",
    ua: "Якість, турбота та унікальність. Ми пишаємося тим, що надаємо найвищу якість послуг. Фахівці VANILLE Salon & SPA - досвідчені професіонали, які приділяють увагу навіть найдрібнішим деталям. Наша студія - це не лише місце для догляду за собою, це досвід, який ми створили з турботою та любов'ю до наших клієнтів. Ми бажаємо допомогти вам відкрити в собі красу, яка буде випромінювати із середини.",
  },
  "content-5": {
    ru: "добро пожаловать в",
    en: "Welcome to",
    de: "Willkommen in",
    ua: "Ласкаво просимо в",
  },
  "content-6": {
    ru: "добро пожаловать в наш дом уюта, гармонии и любви к себе и своему телу",
    en: "Welcome to our home of comfort, harmony, and self-love for both yourself and your body",
    de: "Willkommen in unserem Zuhause des Komforts, der Harmonie und der Selbstliebe, sowohl für sich selbst als auch für ihren Körper",
    ua: "ласкаво просимо до нашого будинку затишку, гармонії та любові до себе та свого тіла",
  },
  "content-7": {
    ru: "Наши услуги",
    en: "Our services",
    de: "Unsere Dienstleistungen",
    ua: "Наші послуги",
  },
  "content-8": {
    ru: "итальянская инновационная технология, которая завоевала заслуженное признание в мире красоты и ухода за телом. Она сочетает в себе передовую медицинскую науку и искусство, предоставляя клиентам уникальные возможности для улучшения внешнего вида и ощущения гармонии внутри",
    en: "talian innovative technology that has earned well-deserved recognition in the world of beauty and body care. It combines advanced medical science and art, offering clients unique opportunities to enhance their external appearance and experience inner harmony",
    de: "Italienische innovative Technologie Endosphères Therapy, die sich weltweit in der Schönheits- und Körperpflege verdiente Anerkennung erworben hat. Sie verbindet fortschrittliche medizinische Wissenschaft und Kunst und bietet Kunden einzigartige Möglichkeiten zur Verbesserung ihres äußeren Erscheinungsbildes und zum Erleben innerer Harmonie",
    ua: "Італійська інноваційна технологія, яка заслужила визнання в світі краси та догляду за тілом. Вона поєднує в собі передову медичну науку та мистецтво, надаючи клієнтам унікальні можливості для поліпшення зовнішнього вигляду та відчуття гармонії всередині.",
  },
  "content-9": {
    ru: "Эндосфера",
    en: "Endosphere therapy",
    de: "Endosphères Therapy",
    ua: "Ендосфера",
  },
  "content-10": {
    ru: "Подробнее...",
    en: "More details...",
    de: "Weitere Details...",
    ua: "Детальніше...",
  },
  "content-11": {
    ru: "Александритовый Лазер",
    en: "Alexandrite Laser",
    de: "Alexandrit-Laser",
    ua: "Александритовий лазер",
  },
  "content-12": {
    ru: "В мире эстетической медицины и в студии Vanille появилась настоящая звезда – александритовый лазер DEKA Again, родом из Италии, который можно назвать безоговорочным Роллс-Ройсом в мире лазерных систем",
    en: "In the world of aesthetic medicine and at Vanille Studio, a true star has emerged - the Alexandrite laser DEKA Again, originating from Italy, which can be called the undisputed Rolls-Royce in the world of laser systems",
    de: "In der Welt der ästhetischen Medizin und im Vanille Studio ist ein echter Star aufgetaucht - der Alexandritlaser DEKA Again, der aus Italien stammt und unbestreitbar als Rolls-Royce in der Welt der Lasersysteme bezeichnet werden kann",
    ua: "У світі естетичної медицини і в студії Vanille з'явився справжній герой - александритовий лазер DEKA Again, виробництва Італія, який можна назвати справжнім Роллс-Ройсом серед лазерних систем.",
  },
  "content-13": {
    ru: "Подробнее...",
    en: "More details...",
    de: "Weitere Details...",
    ua: "Детальніше...",
  },
  "content-14": {
    ru: "Спа процедуры",
    en: "Spa procedures",
    de: "Wellnessanwendungen",
    ua: "Спа-терапія",
  },
  "content-15": {
    ru: "«Искусство расслабления» Мы студия массажа Vanille предлагаем непревзойденный сервис и лучший массаж в Ганновере.Расположенная в сердце Ганновера, предлагаем незабываемые впечатления и высококачественные услуги массажа. Великолепный сервис и роскошная атмосфера превращают каждое посещение в уникальный опыт для всех желающих отдохнуть и расслабиться",
    en: "«The Art of Relaxation» We, the Vanille Massage Studio, offer unparalleled service and the best massage in Hanover. Located in the heart of Hanover, we provide unforgettable experiences and high-quality massage services. Exceptional service and a luxurious atmosphere transform every visit into a unique experience for anyone looking to unwind and relax",
    de: "«Die Kunst der Entspannung»  Wir, das Vanille Massagestudio, bieten unübertroffenen Service und die beste Massage in Hannover. Inmitten von Hannover gelegen, bieten wir unvergessliche Erlebnisse und hochwertige Massagedienstleistungen. Hervorragender Service und eine luxuriöse Atmosphäre machen jeden Besuch zu einem einzigartigen Erlebnis für alle, die sich entspannen möchten",
    ua: "«Мистецтво розслаблення» Ми, студія масажу Vanille, пропонуємо неперевершений сервіс і найкращий масаж у Ганновері. Знаходячись в самому серці Ганновера, ми пропонуємо незабутні враження та високоякісні послуги масажу. Відмінний сервіс та розкішна атмосфера перетворюють кожний візит в унікальний досвід для всіх, хто бажає відпочити і розслабитися.",
  },
  "content-16": {
    ru: "Подробнее...",
    en: "More details...",
    de: "Weitere Details...",
    ua: "Детальніше...",
  },
  "content-17": {
    ru: "Новости",
    en: "News",
    de: "Nachricht",
    ua: "Новини",
  },
  "content-18": {
    ru: "Team member",
    en: "Team member",
    de: "Team member",
    ua: "Team member",
  },
  "content-19": {
    ru: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum optio tenetur autem molestias nobis eveniet quae inventore repellat officia eaque? Atque ea voluptate est debitis maiores voluptas ab ratione autem.",
    en: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum optio tenetur autem molestias nobis eveniet quae inventore repellat officia eaque? Atque ea voluptate est debitis maiores voluptas ab ratione autem.",
    de: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum optio tenetur autem molestias nobis eveniet quae inventore repellat officia eaque? Atque ea voluptate est debitis maiores voluptas ab ratione autem",
    ua: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum optio tenetur autem molestias nobis eveniet ",
  },
  "content-20": {
    ru: "Team member",
    en: "Team member",
    de: "Team member",
    ua: "Team member",
  },
  "content-21": {
    ru: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum optio tenetur autem molestias nobis eveniet quae inventore repellat officia eaque? Atque ea voluptate est debitis maiores voluptas ab ratione autem",
    en: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum optio tenetur autem molestias nobis eveniet quae inventore repellat officia eaque? Atque ea voluptate est debitis maiores voluptas ab ratione autem",
    de: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum optio tenetur autem molestias nobis eveniet quae inventore repellat officia eaque? Atque ea voluptate est debitis maiores voluptas ab ratione autem",
    ua: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum optio tenetur autem molestias nobis eveniet ",
  },
  "content-22": {
    ru: "Team member",
    en: "Team member",
    de: "Team member",
    ua: "Team member",
  },
  "content-23": {
    ru: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum optio tenetur autem molestias nobis eveniet quae inventore repellat officia eaque? Atque ea voluptate est debitis maiores voluptas ab ratione autem",
    en: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum optio tenetur autem molestias nobis eveniet quae inventore repellat officia eaque? Atque ea voluptate est debitis maiores voluptas ab ratione autem",
    de: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum optio tenetur autem molestias nobis eveniet quae inventore repellat officia eaque? Atque ea voluptate est debitis maiores voluptas ab ratione autem",
    ua: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum optio tenetur autem molestias nobis eveniet ",
  },
  "content-24": {
    ru: "Задать вопрос",
    en: "Ask a question",
    de: "Eine Frage stellen",
    ua: "Поставити питання",
  },
  "content-25": {
    ru: "Имя",
    en: "Name",
    de: "Name",
    ua: "Ім'я",
  },
  "content-26": {
    ru: "Телефон",
    en: "Phone",
    de: "Telefon",
    ua: "Телефон",
  },
  "content-27": {
    ru: "Ваше сообщение",
    en: "Your message",
    de: "Ihre Nachricht",
    ua: "Ваше повідомлення",
  },

  "content-28": {
    ru: "я согласен с обработкой моих данных",
    en: "I consent to the processing of my data",
    de: "Ich stimme der Verarbeitung meiner Daten zu",
    ua: "Я згоден з обробкою моїх даних",
  },
  "content-29": {
    ru: "Эндосфера",
    en: "Endosphere Therapy",
    de: "Endosphères Therapy",
    ua: "Ендосфера",
  },
  "content-30": {
    ru: "Александритовый Лазер",
    en: "Alexandrite Laser",
    de: "Alexandrit-Laser",
    ua: "Александритовый Лазер",
  },
  "content-31": {
    ru: "Спа процедуры",
    en: "Spa procedures",
    de: "Wellnessanwendungen",
    ua: "Спа-терапія",
  },
  "content-32": {
    ru: "Скоро будет доступно...",
    en: "Coming soon...",
    de: "Bald verfügbar..",
    ua: "Незабаром буде доступно",
  },
  "content-33": {
    ru: "Bestsellers",
    en: "Bestsellers",
    de: "Bestsellers",
    ua: "Bestsellers",
  },
  "content-34": {
    ru: "О Нас",
    en: "About",
    de: "Über uns",
    ua: "Про нас",
  },
  "content-35": {
    ru: "Наши Услуги",
    en: "Our Services",
    de: "Dienstleistungen",
    ua: "Наші послуги",
  },
  "content-36": {
    ru: "Контакты",
    en: "Contacts",
    de: "Kontakte",
    ua: "Контакти",
  },
  "content-37": {
    ru: "",
    en: "",
    de: "",
    ua: "",
  },
  "content-38": {
    ru: "",
    en: "",
    de: "",
    ua: "",
  },


  "button": {
    ru: "Онлайн запись",
    en: "Online reservation",
    de: "Online-Reservierung",
    ua: "Запис онлайн",
  },
  "button-10": {
    ru: "Онлайн запись",
    en: "Online reservation",
    de: "Online-Reservierung",
    ua: "Запис онлайн",
  },
  "button-11": {
    ru: "Онлайн запись",
    en: "Online reservation",
    de: "Online-Reservierung",
    ua: "Запис онлайн",
  },
  "button-12": {
    ru: "Онлайн запись",
    en: "Online reservation",
    de: "Online-Reservierung",
    ua: "Запис онлайн",
  },
  "button-13": {
    ru: "Онлайн запись",
    en: "Online reservation",
    de: "Online-Reservierung",
    ua: "Запис онлайн",
  },
};


