export const endosphereTexts = {
    "nav-0": {
        ru: "Главная",
        en: "Main page",
        de: "Hauptseite",
        ua: "Головна",
      },
      "nav-1": {
        ru: "Наши Услуги",
        en: "Our Services",
        de: "Dienstleistungen",
        ua: "Наші послуги",
      },
      "nav-2": {
        ru: "О Нас",
        en: "About",
        de: "Über uns",
        ua: "Про нас",
      },
      "nav-3": {
        ru: "Новинки",
        en: "New",
        de: "Neue Dinge",
        ua: "Новінки",
      },
      "nav-4": {
        ru: "Контакты",
        en: "Contacts",
        de: "Kontakte",
        ua: "Контакти",
      },
    "endoshere_page-title": {
        ru: "Vanille Salon&Spa Эндосфера",
        en: "Vanille Salon&Spa Endosphere therapy",
        de: "Vanille Salon&Spa Endosphères Therapy",
        ua: "Vanille Salon&Spa Ендосфера",
    },
    "endoshere_page-title1": {
        ru: "Эндосфера",
        en: "Endosphere therapy",
        de: "Endosphères Therapy",
        ua: "Ендосфера",
    },
    "endoshere_page-title2": {
        ru: "Эндосфера: Технология Красоты и Ухода, Подарок Италии Миру",
        en: "Endosphere: Beauty and Care Technology, Italy's Gift to the World",
        de: "Endosphères Therapy: Schönheits- und Pflegetechnologie, ein Geschenk Italiens an die Welt",
        ua: "Ендосфера: технологія краси та догляду, подарунок Італії світу",
    },
    "endoshere_page-title3": {
        ru: "Эндосфера – это итальянская инновационная технология, которая завоевала заслуженное признание в мире красоты и ухода за телом. Она сочетает в себе передовую медицинскую науку и искусство, предоставляя клиентам уникальные возможности для улучшения внешнего вида и ощущения гармонии внутри",
        en: "Endosphere is an Italian innovative technology that has earned well-deserved recognition in the world of beauty and body care. It combines advanced medical science and art, providing clients with unique opportunities to improve their appearance and experience inner harmony",
        de: "Die Endospheres-Therapie ist eine innovative Technologie aus Italien, die weltweit Anerkennung in der Schönheits- und Körperpflegebranche gefunden hat. Sie vereint fortgeschrittene edizinische Wissenschaft mit kunstvoller Gestaltung und bietet Kunden einzigartige Möglichkeiten zur Verbesserung ihres äußeren Erscheinungsbilds sowie zur Förderung eines harmonischen Gefühls im Inneren",
        ua: "Ендосфера - це італійська інноваційна технологія, яка заслужила визнання в світі краси та догляду за тілом. Вона поєднує в собі передову медичну науку та мистецтво, надаючи клієнтам унікальні можливості для покращення зовнішнього вигляду та відчуття гармонії всередині",
    },
    "endoshere_page-title4": {
        ru: "",
        en: "",
        de: "",
        ua: "",
    },
    "endoshere_page1": {
      ru: "Преобразование и Релаксация",
      en: "Transformation and Relaxation.",
      de: "Transformation und Entspannung",
      ua: "Перетворення і релаксація",
    },
    "endoshere_page2": {
      ru: "Процедура Эндосферы для тела - это специально разработанный метод, который позволяет значительно улучшить состояние вашей кожи и снизить видимость целлюлита. С использованием массажных роликов и аппарата, работающего на основе вакуума, процедура способствует улучшению циркуляции крови, стимулирует лимфодренаж, и помогает уменьшить объемы и упругость кожи. В результате, вы можете ожидать более сглаженную и упругую кожу, которая выглядит и чувствует себя замечательно",
      en: "The Endosphere body procedure is a specially designed method that significantly improves the condition of your skin and reduces the visibility of cellulite. Using massage rollers and a vacuum-based apparatus, the procedure promotes improved blood circulation, stimulates lymphatic drainage, and helps reduce volume and skin elasticity. As a result, you can expect smoother and firmer skin that looks and feels wonderful.",
      de: "Die Endosphères-Therapie ist eine maßgeschneiderte Methode, die wesentlich zur Verbesserung des Hautzustands und zur Reduzierung der Sichtbarkeit von Cellulite beiträgt. Mithilfe von Massagerollen und einer vakuumbasierten Apparatur wird die Durchblutung gefördert, die Lymphdrainage angeregt und das Hautvolumen sowie die Festigkeit verbessert. Das Ergebnis ist eine glattere und straffere Haut, die sich großartig anfühlt und toll aussieht",
      ua: "Процедура Ендосфери для тіла - це спеціально розроблений метод, який значно поліпшує стан вашої шкіри і зменшує видимість целюліту. З використанням масажних роликів та пристрою, що працює на основі вакууму, процедура сприяє покращенню кровообігу, стимулює лімфодренаж та допомагає зменшити об'єми та еластичність шкіри. В результаті ви можете очікувати більш гладку і еластичну шкіру, яка виглядає і відчуває себе чудово",
    },
    "endoshere_page3": {
      ru: "Восстановление и Омоложение",
      en: "Restoration and rejuvenation",
      de: "Wiederherstellung und Verjüngung",
      ua: "Відновлення та омолодження",
    },
    "endoshere_page4": {
      ru: "Процедура Эндосферы для лица - это неинвазивная альтернатива хирургическим процедурам омоложения. Она помогает улучшить тонус кожи, уменьшить морщины и придать лицу свежий и сияющий вид. Массажные ролики и вакуумная технология помогают стимулировать синтез коллагена и укрепляют ткани, обеспечивая лицу более молодой и подтянутый вид",
      en: "The Endosphere facial procedure is a non-invasive alternative to surgical rejuvenation procedures. It helps improve skin tone, reduce wrinkles, and give the face a fresh and radiant appearance. Massage rollers and vacuum technology aid in stimulating collagen synthesis and strengthening tissues, providing a more youthful and tightened appearance to the face",
      de: "Die Endosphären-Therapie bietet eine nicht-invasive Alternative zu chirurgischen Verjüngungsverfahren. Sie verbessert die Hautfestigkeit, reduziert Falten und verleiht dem Gesicht ein frisches und strahlendes Aussehen. Durch die Verwendung von Massagerollen und Vakuumtechnologie wird die Kollagensynthese angeregt und das Gewebe gefestigt, was zu einem jüngeren und strafferen Erscheinungsbild des Gesichts führ",
      ua: "Процедура Ендосфери для обличчя - це неінвазивна альтернатива хірургічним процедурам омолодження. Вона допомагає покращити тонус шкіри, зменшити зморшки та надати обличчю свіже та сяючий вигляд. Масажні ролики та вакуумна технологія допомагають стимулювати синтез колагену та зміцнюють тканини, надаючи обличчю більш молодий і підтягнутий вигляд.",
    },
    "endoshere_page5": {
        ru: "Эндосфера",
        en: "Endosphere therapy",
        de: "Endosphères Therapy",
        ua: "Ендосфера",
      },
    "endoshere_page6": {
      ru: " это итальянская технология номер 1 в мире для улучшения красоты и благополучия тела и лица. Независимо от того, нужно вам омолодить кожу, уменьшить целлюлит или просто расслабиться и почувствовать себя лучше, процедура Эндосферы предлагает индивидуальные решения для вашей уникальной красоты и ухода. Погрузитесь в мир релаксации и ухода с Эндосферой, и ощутите разницу в вашем внешнем виде и самочувствии",
      en: " it is the number one Italian technology in the world for enhancing the beauty and well-being of the body and face. Whether you need to rejuvenate your skin, reduce cellulite, or simply relax and feel better, the Endosphere procedure offers individual solutions for your unique beauty and care. Immerse yourself in the world of relaxation and care with Endosphere, and feel the difference in your appearance and well-being",
      de: " Endosphäre ist die weltweit führende italienische Technologie zur Verbesserung von Schönheit und Wohlbefinden des Körpers und des Gesichts. Ganz gleich, ob Sie Ihre Haut verjüngen, Cellulite reduzieren oder einfach nur entspannen und sich besser fühlen möchten – die Endosphäre-Behandlung bietet individuelle Lösungen für Ihre einzigartige Schönheit und Pflege. Tauchen Sie ein in die Welt der Entspannung und Pflege mit Endosphäre und spüren Sie den Unterschied in Ihrem äußeren Erscheinungsbild und Ihrem Wohlbefinden",
      ua: " ця італійська технологія є номером 1 в світі для покращення краси та благополуччя тіла та обличчя. Незалежно від того, чи вам потрібно омолодити шкіру, зменшити целюліт або просто розслабитися та почувати себе краще, процедура Ендосфери пропонує індивідуальні рішення для вашої унікальної краси та догляду. Погрузьтеся в світ релаксації та догляду з Ендосферою і відчуйте різницю у вашому зовнішньому вигляді та самопочутті.",
    },
    "endoshere_page7": {
      ru: "",
      en: "",
      de: "",
      ua: "",
    },
    "endoshere_page8": {
      ru: "",
      en: "",
      de: "",
      ua: "",
    },
    "content-24": {
        ru: "Задать вопрос",
        en: "Ask a question",
        de: "Eine Frage stellen",
        ua: "Поставити питання",
      },
      "content-25": {
        ru: "Имя",
        en: "Name",
        de: "Name",
        ua: "Ім'я",
      },
      "content-26": {
        ru: "Телефон",
        en: "Phone",
        de: "Telefon",
        ua: "Телефон",
      },
      "content-27": {
        ru: "Ваше сообщение",
        en: "Your message",
        de: "Ihre Nachricht",
        ua: "Ваше повідомлення",
      },
    
      "content-28": {
        ru: "я согласен с обработкой моих данных",
        en: "I consent to the processing of my data",
        de: "Ich stimme der Verarbeitung meiner Daten zu",
        ua: "Я згоден з обробкою моїх даних",
      },
    "content-33": {
      ru: "Bestsellers",
      en: "Bestsellers",
      de: "Bestsellers",
      ua: "Bestsellers",
      },
      "content-34": {
        ru: "О Нас",
        en: "About",
        de: "Über uns",
        ua: "Про нас",
      },
      "content-35": {
        ru: "Наши Услуги",
        en: "Our Services",
        de: "Dienstleistungen",
        ua: "Наші послуги",
      },
      "content-36": {
        ru: "Контакты",
        en: "Contacts",
        de: "Kontakte",
        ua: "Контакти",
      },
      "content-37": {
        ru: "",
        en: "",
        de: "",
        ua: "",
      },
      "content-38": {
        ru: "",
        en: "",
        de: "",
        ua: "",
      },
    
    
      "button": {
        ru: "Онлайн запись",
        en: "Online reservation",
        de: "Online-Reservierung",
        ua: "Запис онлайн",
      },
      "button-1": {
        ru: "Онлайн запись",
        en: "Online reservation",
        de: "Online-Reservierung",
        ua: "Запис онлайн",
      },
      "button-2": {
        ru: "Онлайн запись",
        en: "Online reservation",
        de: "Online-Reservierung",
        ua: "Запис онлайн",
      },
      "button-3": {
        ru: "Онлайн запись",
        en: "Online reservation",
        de: "Online-Reservierung",
        ua: "Запис онлайн",
      },
      "button-3": {
        ru: "Отправить",
        en: "Send",
        de: "Senden",
        ua: "Надіслати",
      },
}