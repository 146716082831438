export const spaTexts = {
    "nav-0": {
        ru: "Главная",
        en: "Main page",
        de: "Hauptseite",
        ua: "Головна",
      },
      "nav-1": {
        ru: "Наши Услуги",
        en: "Our Services",
        de: "Dienstleistungen",
        ua: "Наші послуги",
      },
      "nav-2": {
        ru: "О Нас",
        en: "About",
        de: "Über uns",
        ua: "Про нас",
      },
      "nav-3": {
        ru: "Новинки",
        en: "New",
        de: "Neue Dinge",
        ua: "Новінки",
      },
      "nav-4": {
        ru: "Контакты",
        en: "Contacts",
        de: "Kontakte",
        ua: "Контакти",
      },
    "spa_page-title": {
        ru: "",
        en: "",
        de: "",
        ua: "",
    },
    "spa_page-title2": {
        ru: "",
        en: "",
        de: "",
        ua: "",
    },
    "spa_page-title3": {
        ru: "",
        en: "",
        de: "",
        ua: "",
    },
    "spa_page-title4": {
        ru: "",
        en: "",
        de: "",
        ua: "",
    },
    "spa_page1": {
      ru: "",
      en: "",
      de: "",
      ua: "",
    },
    "content-24": {
        ru: "Задать вопрос",
        en: "Ask a question",
        de: "Eine Frage stellen",
        ua: "Поставити питання",
      },
      "content-25": {
        ru: "Имя",
        en: "Name",
        de: "Name",
        ua: "Ім'я",
      },
      "content-26": {
        ru: "Телефон",
        en: "Phone",
        de: "Telefon",
        ua: "Телефон",
      },
      "content-27": {
        ru: "Ваше сообщение",
        en: "Your message",
        de: "Ihre Nachricht",
        ua: "Ваше повідомлення",
      },
    
      "content-28": {
        ru: "я согласен с обработкой моих данных",
        en: "I consent to the processing of my data",
        de: "Ich stimme der Verarbeitung meiner Daten zu",
        ua: "Я згоден з обробкою моїх даних",
      },
    "content-33": {
      ru: "Bestsellers",
      en: "Bestsellers",
      de: "Bestsellers",
      ua: "Bestsellers",
      },
      "content-34": {
        ru: "О Нас",
        en: "About",
        de: "Über uns",
        ua: "Про нас",
      },
      "content-35": {
        ru: "Наши Услуги",
        en: "Our Services",
        de: "Dienstleistungen",
        ua: "Наші послуги",
      },
      "content-36": {
        ru: "Контакты",
        en: "Contacts",
        de: "Kontakte",
        ua: "Контакти",
      },
      "content-37": {
        ru: "",
        en: "",
        de: "",
        ua: "",
      },
      "content-38": {
        ru: "",
        en: "",
        de: "",
        ua: "",
      },
    
    
      "button": {
        ru: "Онлайн запись",
        en: "Online reservation",
        de: "Online-Reservierung",
        ua: "Запис онлайн",
      },
      "button-1": {
        ru: "Онлайн запись",
        en: "Online reservation",
        de: "Online-Reservierung",
        ua: "Запис онлайн",
      },
      "button-2": {
        ru: "Онлайн запись",
        en: "Online reservation",
        de: "Online-Reservierung",
        ua: "Запис онлайн",
      },
      "button-3": {
        ru: "Отправить",
        en: "Send",
        de: "Senden",
        ua: "Надіслати",
      },
}