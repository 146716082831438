import { homeTexts } from "../text/text";
import { laserTexts } from "../text/laserPage";
import { endosphereTexts } from "../text/endospherePage";
import { spaTexts } from "../text/spaPage";
import { contactsTexts } from "../text/contactsPage";
import { newsTexts } from "../text/newsPage";


const langButtons = document.querySelectorAll("[data-btn]");
const allLangs = ["ru", "en", "de", "ua"];
const currentPathName = window.location.pathname;
let currentLang =
  localStorage.getItem("language") || checkBrowserLang() || "de";
let currentTexts = {};

// Проверка пути страницы сайта
function checkPagePathName() {
  switch (currentPathName) {
    case "/index.html":
      currentTexts = homeTexts;
      break;
    case "/about.html":
      currentTexts = homeTexts;
      break;
    case "/under.html":
      currentTexts = homeTexts;
      break;
    case "/laser.html":
      currentTexts = laserTexts;
      break;
    case "/endosphere.html":
      currentTexts = endosphereTexts;
      break;
    case "/spa.html":
      currentTexts = spaTexts;
      break;
    case "/contacts.html":
      currentTexts = contactsTexts;
      break;
    case "/news.html":
      currentTexts = newsTexts;
      break;

    default:
      currentTexts = homeTexts;
      break;
  }
}
checkPagePathName();

// Изменение языка у текстов
function changeLang() {
  for (const key in currentTexts) {
    let elem = document.querySelector(`[data-lang=${key}]`);
    if (elem) {
      elem.textContent = currentTexts[key][currentLang];
    }
  }
}
changeLang();

// Вешаем обработчики на каждую кнопку
langButtons.forEach((btn) => {
  btn.addEventListener("click", (event) => {
    if (!event.target.classList.contains("header__btn_active")) {
      currentLang = event.target.dataset.btn;
      localStorage.setItem("language", event.target.dataset.btn);
      resetActiveClass(langButtons, "header__btn_active");
      btn.classList.add("header__btn_active");
      changeLang();
    }
  });
});

// Сброс активного класса у переданного массива элементов
function resetActiveClass(arr, activeClass) {
  arr.forEach((elem) => {
    elem.classList.remove(activeClass);
  });
}

// Проверка активной кнопки
function checkActiveLangButton() {
  switch (currentLang) {
    case "ru":
      document
        .querySelector('[data-btn="ru"]')
        .classList.add("header__btn_active");
      break;
    case "en":
      document
        .querySelector('[data-btn="en"]')
        .classList.add("header__btn_active");
      break;
    case "de":
      document
        .querySelector('[data-btn="de"]')
        .classList.add("header__btn_active");
      break;
    case "ua":
      document
        .querySelector('[data-btn="ua"]')
        .classList.add("header__btn_active");
      break;

    default:
      document
        .querySelector('[data-btn="de"]')
        .classList.add("header__btn_active");
      break;
  }
}
checkActiveLangButton();

// Проверка языка браузера
function checkBrowserLang() {
  const navLang = navigator.language.slice(0, 2).toLowerCase();
  const result = allLangs.some((elem) => {
    return elem === navLang;
  });
  if (result) {
    return navLang;
  }
}

function defaultLanguage() {
  document.querySelector(".selected").innerHTML = currentLang.toUpperCase();
}

defaultLanguage();



