const revealElements = document.querySelectorAll("[data-reveal]");

const scrollReveal = function(){
  for(let i = 0; i < revealElements.length; i++){
    const isElementsOnScreen = revealElements[i].getBoundingClientRect().top < window.innerHeight;
    
    if(isElementsOnScreen){
      revealElements[i].classList.add("revealed")
    } else {
      revealElements[i].classList.remove("revealed")
    }
  }
}

const scaleElements = document.querySelectorAll("[data-scale]");

const scrollScale = function(){
  for(let i = 0; i < scaleElements.length; i++){
    const isElementsOnScreen = scaleElements[i].getBoundingClientRect().top < window.innerHeight;
    
    if(isElementsOnScreen){
      scaleElements[i].classList.add("scaled")
    } else {
      scaleElements[i].classList.remove("scaled")
    }
  }
}



window.addEventListener("scroll", scrollScale);
window.addEventListener("load", scrollScale);
window.addEventListener("scroll", scrollReveal);
window.addEventListener("load", scrollReveal);


