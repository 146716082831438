!function(e,r){
    if(e.EBooking)console.error("Vanille.Me embed already included");
    else{e.EBooking={},m=["init"],e.EBooking._c=[],m.forEach(r=>e.EBooking[r]=function(){e.EBooking._c.push([r,arguments])});
    var a=r.createElement("script");a.type="text/javascript",a.async=!0,a.src="https://widget.vanille.me/shim.js";
    var n=r.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n)}}(window,document),EBooking.init("65e1af58fa3351264a971e39");


function initBooking() {
    // const bookingWind = document.getElementById('E-booking-container');
     $('#E-booking-container').css({display:'block', width:"50vw", position:"stiky", left:"25vw", top:"10vh", height:"60vh"});

    //  console.log(bookingWind);
    //  console.log($('#E-booking-container'));

}


// 'display:block, width:50vw, position:stiky, left:25vw, top:10vh, height:60vh'
