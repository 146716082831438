export const contactsTexts = {
  "nav-1": {
    ru: "Bestsellers",
    en: "Bestsellers",
    de: "Bestsellers",
    ua: "Bestsellers",
  },
  "nav-2": {
    ru: "О Нас",
    en: "About",
    de: "Über uns",
    ua: "Про нас",
  },
  "nav-3": {
    ru: "Bestsellers",
    en: "Bestsellers",
    de: "Bestsellers",
    ua: "Bestsellers",
  },
  "nav-4": {
    ru: "Контакты",
    en: "Contacts",
    de: "Kontakte",
    ua: "Контакти",
  },
  "content-33": {
    ru: "Bestsellers",
    en: "Bestsellers",
    de: "Bestsellers",
    ua: "Bestsellers",
  },
  "content-34": {
    ru: "О Нас",
    en: "About",
    de: "Über uns",
    ua: "Про нас",
  },
  "content-35": {
    ru: "Наши Услуги",
    en: "Our Services",
    de: "Dienstleistungen",
    ua: "Наші послуги",
  },
  "content-36": {
    ru: "Контакты",
    en: "Contacts",
    de: "Kontakte",
    ua: "Контакти",
  },
  "content-50": {
    ru: "Контакты",
    en: "Contacts",
    de: "Kontakte",
    ua: "Контакти",
  },
};
