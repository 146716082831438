
const menuButn = document.querySelector('.header__nav--btn');
const menuu = document.querySelector('.header__nav');

menuButn.addEventListener('click', function(){
  console.log("Alarm")
	menuButn.classList.toggle('active');
	menuu.classList.toggle('active');
})

// window.onscroll = function() {stickyHeader()};

// const header = document.getElementById("myHeader");
// const sticky = header.offsetTop;

function stickyHeader() {
  if (window.scrollY >= sticky) {
    header.classList.add("sticky");
  } else {
    header.classList.remove("sticky");
  }
}
